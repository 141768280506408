
.dms-login-page-overflow-hidden{
    overflow: hidden;
}

.dms-navbar {
    display: flex;
    justify-content: space-between;
    gap:40px;
    align-items: center;
    background-color: #EDE9DD;
    padding: 10px 20px;
}

/* Logo styles */
.dms-logo{
    font-weight: bold;
    font-size: 18px;
    color: #36454F;
}
.dms-logo img {
    width: 50px;
    height: 50px;
}

/* Menu items container */
.dms-menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font:inherit;
}

/* Menu items */
.dms-menu li {
    margin-left: 20px;
}

.dms-menu a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #23241f;
    text-transform: uppercase;
}

.dms-menu a:hover {
    color: #23241f;
    text-decoration: underline;
}

/* Hamburger menu styles (hidden by default) */
.dms-hamburger {
    display: none;
    font-size: 30px;
    color: black;
    cursor: pointer;
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
    /* Hide menu items on small screens */
    .dms-menu {
        display: none;
        width: 100%;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #333;
    }

    .dms-menu li {
        text-align: center;
        margin: 10px 0;
    }

    .dms-hamburger {
        display: block;
    }

    /* Toggle menu visibility */
    .dms-menu.active {
        display: flex;
        z-index: 999;
    }
    .dms-menu.active a {
        color: #EDE9DD;
    }
}