ul.accidentReport {list-style-type: square;}

.justifyText{
    text-align: justify;
}

.topFormSection{
    padding: 40px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

}

.bottomFormSection {
    padding: 50px;
}

small{
    color:#025875 !important;
    padding-left: 3px;
}

.success_valid{
    color: #198754 !important;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.success_valid > input, .success_valid > select, .success_valid > textarea, .suvcess_valid > label{
    border-color: #198754 !important;
    color: #198754 !important;
}


.error_invalid{
    color: #dc3545 !important;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.error_invalid > input, .error_invalid > select, .error_invalid > textarea, .error_invalid > label{
    border-color: #dc3545 !important;
    color: #dc3545 !important;
}

div.react-datepicker__input-container input{
    display: block !important;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


@media only screen and (max-width: 900px) {
    .main {
       width:95% !important;
    }

    .topFormSection, .bottomFormSection {
        padding: 20px;
    }

}